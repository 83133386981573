import type { BaseLemonEvent } from '../analytics.types'

const dealEvents = [
  {
    key: 'user intent - ownership selected',
    description: 'O usuário escolheu o seu tipo de titularidade',
  },
  {
    key: 'server event - deal loading view',
    description: 'O usuário visualizou a página de carregamento do deal',
  },
  {
    key: 'server event - deal signature view',
    description: 'O usuário visualizou a página de assinatura do deal',
  },
  {
    key: 'server event - deal missing embed url',
    description:
      'O link da docusign leva tempo pra ser gerado. A gente espera um certo periodo de tempo (10 segundos~), e se não tiver, vamos tentar novamente ou dar um erro em caso de máximo de tentativas.',
  },
  {
    key: 'client event - evaluate deal parameters',
    description:
      'Quais paramêtros foram usados no componente <EvaluateDeal />. Útil pra debugar casos de erro.',
  },
  {
    key: 'server event - evaluate deal - creating lead',
    description:
      'O fluxo está criando um novo lead através do componente <EvaluateDeal />',
  },
  {
    key: 'server event - evaluate deal - updating lead',
    description:
      'O fluxo está atualizando um lead existente através do componente <EvaluateDeal />',
  },
  {
    key: 'server event - evaluate deal - creating deal',
    description:
      'O fluxo está criando um novo deal através do componente <EvaluateDeal />',
  },
  {
    key: 'server event - evaluate deal - requesting contract embed',
    description:
      'O fluxo está pedindo um link de embed para o contrato através do componente <EvaluateDeal />',
  },
] as const satisfies BaseLemonEvent[]

export default dealEvents
