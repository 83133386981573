import type { BaseLemonEvent } from '../analytics.types'

const selfServiceEvents = [
  {
    key: 'lead created',
    description: 'O lead foi criado',
  },
  {
    key: 'lead updated',
    description: 'Propriedades do lead sofreram mutações',
  },
  {
    key: 'contract created',
    description: 'Um contrato (docusign) foi criado para o deal',
  },
  {
    key: 'contract signed',
    description: 'O deal assinou o contrato (docusign)',
  },
] as const satisfies BaseLemonEvent[]

export default selfServiceEvents
