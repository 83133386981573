import type { BaseLemonEvent } from '../analytics.types'

const documentDetectorHookEvents = [
  {
    key: 'client event - sdk setup - success',
    description: 'SDK foi configurado com sucesso',
  },
  {
    key: 'client event - sdk setup - failure',
    description: 'SDK falhou no momeno da configuração',
  },
  {
    key: 'client event - camera permission - granted',
    description:
      'Permissão de câmera concedida (Se for versão 6.2.0~ da CAF, antes da refatoração)',
  },
  {
    key: 'client event - camera permission - denied',
    description:
      'Permissão de câmera negada (Se for versão 6.2.0~ da CAF, antes da refatoração)',
  },
  {
    key: 'client event - sdk initialization - success',
    description: 'SDK foi inicializado com sucesso',
  },
  {
    key: 'client event - sdk initialization - failure',
    description: 'SDK falhou em sua inicialização',
  },
  {
    key: 'client event - capture result',
    description: 'Resultado da captura do documento',
  },
  {
    key: 'client event - sdk shutdown',
    description: 'SDK foi desligado',
  },
] as const satisfies BaseLemonEvent[]

const documentCollectingEvents = [
  {
    key: 'user intent - confirm identity',
    description: 'Usuário decidiu confirmar identidade',
  },
  {
    key: 'user intent - skip identity',
    description: 'Usuário decidiu pular identidade',
  },
  {
    key: 'user intent - document type - rg',
    description: 'Usuário escolheu RG',
  },
  {
    key: 'user intent - document type - cnh',
    description: 'Usuário escolheu CNH',
  },
  {
    key: 'user intent - document type - cnh digital',
    description: 'Usuário escolheu CNH Digital',
  },
  {
    key: 'user intent - capture options - camera',
    description: 'Usuário escolheu capturar com câmera (CAF)',
  },
  {
    key: 'user intent - capture options - upload',
    description: 'Usuário escolheu enviar com upload (CAF)',
  },
  {
    key: 'user intent - back to front - upload',
    description:
      'Usuário decidiu voltar para upload da frente do documento novamente',
  },
  {
    key: 'user intent - try again',
    description: 'Usuário seleciou tentar novamente (CAF) e foi pro fallback',
  },
  {
    key: 'server event - document version',
    description: 'Versão do SDK da CAF que foi usado',
  },
  {
    key: 'server event - will skip bill',
    description:
      'Se esse fluxo vai pular envio de conta e ir direto pra tela de sucesso',
  },
  {
    key: 'client event - document collected',
    description: 'Documento coletado com sucesso (Frente/Trás)',
  },
  {
    key: 'client event - all documents collected',
    description: 'Todos os documentos foram coletados com sucesso',
  },
  {
    key: 'client event - sdk script error - retry',
    description: 'Erro de carregamento do script do SDK (Tentando novamente)',
  },
  {
    key: 'client event - sdk script error - fallback',
    description: 'Erro de carregamento do script do SDK (Foi pro fallback)',
  },
  ...documentDetectorHookEvents,
] as const satisfies BaseLemonEvent[]

export default documentCollectingEvents
