import type { BaseLemonEvent } from '../analytics.types'

const interactionEvents = [
  {
    key: 'economy calculator - result changed',
    description: 'O usuário alterou valores do simulador de economia',
  },
] as const satisfies BaseLemonEvent[]

const commonEvents = [
  {
    key: 'user navigation - back (native)',
    description:
      'O usuário navegou para trás através do botão de voltar do dispositivo ou navegador',
  },
  {
    key: 'user navigation - back (remix)',
    description:
      'O usuário navegou para trás através de um botão de link ou api do remix',
  },
  {
    key: 'webview detected',
    description: 'Nosso fluxo detectou um acesso de webview.',
  },
  ...interactionEvents,
] as const satisfies BaseLemonEvent[]

export default commonEvents
