import lemonEvents from './analytics.events'

/**
 * Map containing all available event configurations indexed by their keys
 */
export const eventsMap = new Map(
  lemonEvents.map(event => [event.key, event] as const),
)

/**
 * Type representing valid event keys that can be used with the reporting functions
 */
export type LemonEventsKey = Parameters<typeof eventsMap.get>[0]

/**
 * Retrieves an event configuration from the events map by its key
 *
 * @param eventKey - The unique identifier for the event to retrieve
 * @returns A tuple containing [key, eventProperties] as const, or null if event not found
 */
export const getEventFromMap = (eventKey: LemonEventsKey) => {
  const event = eventsMap.get(eventKey)

  if (!event) {
    console.warn(`Event ${eventKey} not found`)
    return null
  }

  const { key, ...rest } = event

  return [key, rest] as const
}
