import { getEventFromMap, type LemonEventsKey } from './analytics.shared'
import type { FullLemonEvent, LemonEventMeta } from './analytics.types'

import { capture } from '~/utils/tracking'
import { parseUtms } from '~/utils/utms.utils'

/**
 * Reports an event to client-side analytics tools
 *
 * @param eventKey - The key of the event to report
 * @param meta - Optional additional metadata to include with the event
 * @param source - The URL object representing the source of the event
 * @returns void
 */
export const reportClient = ({
  eventKey,
  meta,
  source,
}: {
  eventKey: LemonEventsKey
  meta?: LemonEventMeta
  source?: URL
}) => {
  const event = getEventFromMap(eventKey)
  if (!event) return

  const urlSource = source ?? new URL(window.location.href)

  const fullEvent: FullLemonEvent = {
    key: eventKey,
    ...event,
    meta: {
      ...meta,
      ...parseUtms(urlSource.searchParams),
    },
    environment: 'client',
    source: urlSource.pathname,
  }

  const { key, ...rest } = fullEvent

  capture({
    eventType: key,
    eventParams: {
      ...rest,
    },
  })
}

/**
 * Reports multiple events to client-side analytics tools
 *
 * @param options - Object containing the events to report
 * @param options.events - Array of tuples where first element is the eventKey and second element is an object containing eventKey, optional meta, and source URL
 * @returns void
 */
export const reportManyClient = ({
  events,
}: {
  events: [
    LemonEventsKey,
    {
      eventKey: LemonEventsKey
      meta?: LemonEventMeta
      source?: URL
    },
  ][]
}) => {
  events.forEach(([eventKey, { meta, source }]) => {
    reportClient({ eventKey, meta, source })
  })
}
